:root {
  .blue {
    --order-color: blue;
  }

  .black {
    --order-color: black;
  }

  .green {
    --order-color: green;
  }

  .grey {
    --order-color: grey;
  }

  .orange {
    --order-color: orange;
  }

  .purple {
    --order-color: purple;
  }

  .turquoise {
    --order-color: turquoise;
  }

  .gold {
    --order-color: gold;
  }

  .aquamarine {
    --order-color: aquamarine;
  }

  .cadetblue {
    --order-color: cadetblue;
  }

  .darkcyan {
    --order-color: darkcyan;
  }

  .deeppink {
    --order-color: deeppink;
  }

  .maroon {
    --order-color: maroon;
  }

  .olive {
    --order-color: olive;
  }

  .palevioletred {
    --order-color: palevioletred;
  }

  .plum {
    --order-color: plum;
  }

  .salmon {
    --order-color: salmon;
  }

  .yellowgreen {
    --order-color: yellowgreen;
  }

  .antiquewhite {
    --order-color: antiquewhite;
  }

  .aqua {
    --order-color: aqua;
  }

  .beige {
    --order-color: beige;
  }

  .bisque {
    --order-color: bisque;
  }

  .blanchedalmond {
    --order-color: blanchedalmond;
  }

  .blueviolet {
    --order-color: blueviolet;
  }

  .brown {
    --order-color: brown;
  }

  .burlywood {
    --order-color: burlywood;
  }

  .chartreuse {
    --order-color: chartreuse;
  }

  .chocolate {
    --order-color: chocolate;
  }

  .coral {
    --order-color: coral;
  }

  .cornflowerblue {
    --order-color: cornflowerblue;
  }

  .cornsilk {
    --order-color: cornsilk;
  }

  .crimson {
    --order-color: crimson;
  }

  .cyan {
    --order-color: cyan;
  }

  .darkblue {
    --order-color: darkblue;
  }

  .darkgoldenrod {
    --order-color: darkgoldenrod;
  }

  .darkgray {
    --order-color: darkgray;
  }

  .darkgreen {
    --order-color: darkgreen;
  }

  .darkkhaki {
    --order-color: darkkhaki;
  }

  .darkmagenta {
    --order-color: darkmagenta;
  }

  .darkolivegreen {
    --order-color: darkolivegreen;
  }

  .darkorange {
    --order-color: darkorange;
  }

  .darkorchid {
    --order-color: darkorchid;
  }

  .darkred {
    --order-color: darkred;
  }

  .darksalmon {
    --order-color: darksalmon;
  }

  .darkseagreen {
    --order-color: darkseagreen;
  }

  .darkslateblue {
    --order-color: darkslateblue;
  }

  .darkslategray {
    --order-color: darkslategray;
  }

  .darkturquoise {
    --order-color: darkturquoise;
  }

  .darkviolet {
    --order-color: darkviolet;
  }

  .deepskyblue {
    --order-color: deepskyblue;
  }

  .dimgray {
    --order-color: dimgray;
  }

  .dodgerblue {
    --order-color: dodgerblue;
  }

  .firebrick {
    --order-color: firebrick;
  }

  .forestgreen {
    --order-color: forestgreen;
  }

  .fuchsia {
    --order-color: fuchsia;
  }

  .gainsboro {
    --order-color: gainsboro;
  }

  .goldenrod {
    --order-color: goldenrod;
  }

  .gray {
    --order-color: gray;
  }

  .greenyellow {
    --order-color: greenyellow;
  }

  .hotpink {
    --order-color: hotpink;
  }

  .indianred {
    --order-color: indianred;
  }

  .indigo {
    --order-color: indigo;
  }

  .khaki {
    --order-color: khaki;
  }

  .lavender {
    --order-color: lavender;
  }

  .lavenderblush {
    --order-color: lavenderblush;
  }

  .lawngreen {
    --order-color: lawngreen;
  }

  .lemonchiffon {
    --order-color: lemonchiffon;
  }

  .lightblue {
    --order-color: lightblue;
  }

  .lightcoral {
    --order-color: lightcoral;
  }

  .lightcyan {
    --order-color: lightcyan;
  }

  .lightgoldenrodyellow {
    --order-color: lightgoldenrodyellow;
  }

  .lightgreen {
    --order-color: lightgreen;
  }

  .lightgrey {
    --order-color: lightgrey;
  }

  .lightpink {
    --order-color: lightpink;
  }

  .lightsalmon {
    --order-color: lightsalmon;
  }

  .lightseagreen {
    --order-color: lightseagreen;
  }

  .lightskyblue {
    --order-color: lightskyblue;
  }

  .lightslategray {
    --order-color: lightslategray;
  }

  .lightsteelblue {
    --order-color: lightsteelblue;
  }

  .lightyellow {
    --order-color: lightyellow;
  }

  .lime {
    --order-color: lime;
  }

  .limegreen {
    --order-color: limegreen;
  }

  .linen {
    --order-color: linen;
  }

  .magenta {
    --order-color: magenta;
  }

  .mediumaquamarine {
    --order-color: mediumaquamarine;
  }

  .mediumblue {
    --order-color: mediumblue;
  }

  .mediumorchid {
    --order-color: mediumorchid;
  }

  .mediumpurple {
    --order-color: mediumpurple;
  }

  .mediumseagreen {
    --order-color: mediumseagreen;
  }

  .mediumslateblue {
    --order-color: mediumslateblue;
  }

  .mediumspringgreen {
    --order-color: mediumspringgreen;
  }

  .mediumturquoise {
    --order-color: mediumturquoise;
  }

  .mediumvioletred {
    --order-color: mediumvioletred;
  }

  .midnightblue {
    --order-color: midnightblue;
  }

  .mistyrose {
    --order-color: mistyrose;
  }

  .moccasin {
    --order-color: moccasin;
  }

  .navajowhite {
    --order-color: navajowhite;
  }

  .navy {
    --order-color: navy;
  }

  .oldlace {
    --order-color: oldlace;
  }

  .olivedrab {
    --order-color: olivedrab;
  }

  .orangered {
    --order-color: orangered;
  }

  .orchid {
    --order-color: orchid;
  }

  .palegoldenrod {
    --order-color: palegoldenrod;
  }

  .palegreen {
    --order-color: palegreen;
  }

  .paleturquoise {
    --order-color: paleturquoise;
  }

  .papayawhip {
    --order-color: papayawhip;
  }

  .peachpuff {
    --order-color: peachpuff;
  }

  .peru {
    --order-color: peru;
  }

  .pink {
    --order-color: pink;
  }

  .powderblue {
    --order-color: powderblue;
  }

  .rosybrown {
    --order-color: rosybrown;
  }

  .royalblue {
    --order-color: royalblue;
  }

  .saddlebrown {
    --order-color: saddlebrown;
  }

  .sandybrown {
    --order-color: sandybrown;
  }

  .seagreen {
    --order-color: seagreen;
  }

  .seashell {
    --order-color: seashell;
  }

  .sienna {
    --order-color: sienna;
  }

  .silver {
    --order-color: silver;
  }

  .skyblue {
    --order-color: skyblue;
  }

  .slateblue {
    --order-color: slateblue;
  }

  .slategray {
    --order-color: slategray;
  }

  .springgreen {
    --order-color: springgreen;
  }

  .steelblue {
    --order-color: steelblue;
  }

  .tan {
    --order-color: tan;
  }

  .teal {
    --order-color: teal;
  }

  .thistle {
    --order-color: thistle;
  }

  .tomato {
    --order-color: tomato;
  }

  .violet {
    --order-color: violet;
  }

  .wheat {
    --order-color: wheat;
  }

  .yellow {
    --order-color: yellow;
  }
  .red {
    --order-color: red;
  }
}
