/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/app";
@import "theme/route-colors";
//@import "theme/loading";

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "intro.js/introjs.css";

@import 'theme/fonts';

@import "leaflet/dist/leaflet.css";
@import "leaflet-draw/dist/leaflet.draw.css";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "theme/bootstrap-grid.css";
@import "theme/ionic-alert";
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
