// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #1774ff;
  --ion-color-primary-rgb: 23,116,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1466e0;
  --ion-color-primary-tint: #2e82ff;

  --ion-color-secondary: #cfe1fc;
  --ion-color-secondary-rgb: 207,225,252;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #b6c6de;
  --ion-color-secondary-tint: #d4e4fc;

  --ion-color-tertiary: #2b1292;
  --ion-color-tertiary-rgb: 43,18,146;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #261080;
  --ion-color-tertiary-tint: #402a9d;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-item-background:#f1f2f7;

  .toast-button {
    color: white !important;
    --ion-color-primary: white;
  }

  .mat-raised-button.mat-primary {
    background-color: #1774ff;
  }
  .mat-stroked-button.mat-primary {
    color: #1774ff;
  }
  .mat-mini-fab.mat-primary {
    background-color: #1774ff;
  }
  .mat-ink-bar {
    background-color: #1774ff !important;
  }

  ion-modal {
    @media screen and (min-width: 768px) {
      --border-radius: 15px;
    }

    ion-toolbar {
      box-shadow: none;
    }
  }
  --mdc-filled-text-field-container-color: white;
  --mdc-text-button-label-text-color: #1774ff;
  //--mat-form-field-subscript-text-line-height: 0;
  --mat-form-field-filled-with-label-container-padding-top: 21px;
  --mat-form-field-container-height: 50px;
  .footer-md {
    box-shadow: none;
  }

  --mat-dialog-container-small-max-width:100vw;
}

ion-input {
  --ion-item-background: white;
}
ion-card {
  --ion-item-background: white;
}
$shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;


