@import "src/theme/variables";
$border-radius: 15px;
ion-content {
  --background: #f5f5f5;
}
.grey-background {
  background: #f5f5f5;
  --background: #f5f5f5;
}

ion-card {
  background: white;
  --background: white;
  padding-right: 17px;
  padding-left: 17px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  border-radius: 8px;
  border: none;
}

ion-toolbar {

  &.round {
    border-radius: 0 0 10px 10px;
  }

  --background: white;

  :after {
    display: none !important;
  }

}

ion-header {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow !important;
  background: #f1f2f7;
}

ion-footer {
  background: white;
}

.border {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

.header-md:after {
  display: none;
}

.header-ios ion-toolbar:last-child {
  --border-width: 0;
}

.button-round {
  --border-radius: 10px;
}

.round-item {
  //-webkit-box-shadow: $shadow;
  //-moz-box-shadow: $shadow;
  //box-shadow: $shadow;
  border: 2px solid #f1f2f7;
  border-radius: 8px;
  --border-radius: 8px;
  height: 45px;
  margin: 16px 3px 10px 10px;
  --padding-start: 20px;
  --ion-item-background: white;

  span {
    margin-right: 10px;
  }
}

ion-menu.menu-side-start::part(container) {
  border-start-end-radius: 20px;
  border-end-end-radius: 20px;
}

ion-menu.menu-side-end::part(container) {
  @media screen and (min-width: 768px) {
    border-start-start-radius: 20px;
    border-end-start-radius: 20px;
  }
}
.main-menu {

  .menu-header {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
    height: 15%;
    max-height: 130px;
    min-height: 63px;
    margin-inline-start: 30px;
    margin-inline-end: 10px;
  }

  h3 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }

  p {
    color: white;
  }

  .active {
    --background: #0d0637;
    border-radius: 25px;
    position: relative;
    left: 21px;
    z-index: 100;
    box-shadow: $shadow;
    display: block;
  }
  .active.item-rtl {
    left: 0;
    right: 21px;
  }

  ion-content {
    --background: #2b1292;
  }

  ion-list {
    background: #2b1292;

    ion-item {
      --background: #2b1292;
      color: white;
      font-weight: 500;
      border-radius: 25px 0 0 25px;

      ion-label {
        text-align: start;
        margin-inline-start: 34px;

        ion-icon {
          margin-right: 10px;
        }
      }

      &:hover {
        --background: #f1f2f7;
      }

      &.sub-child {
        margin-left: 50px;
        display: none;
      }
    }
  }

  ion-footer {
    background: #2b1292;
    color: white;
    font-weight: 400;
  }
}

.list-ios {
  --ion-item-background: white;
}


ion-input {
  /*-webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;*/
  border: 2px solid #f1f2f7;
  border-radius: 12px;
  height: 45px;
  margin: 13px;
  width: 95% !important;
  margin-top: 30px;
  --padding-start: 20px;
  --ion-item-background: white;

  .native-input.sc-ion-input-md {
    padding-left: 10px;
    padding-right: 10px;
  }

  .native-input.sc-ion-input-ios {
    padding-left: 10px;
    padding-right: 10px;
  }

}

ion-textarea {
  /*-webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;*/
  border: 2px solid #f1f2f7;
  border-radius: 25px;
  height: 45px;
  margin: 13px !important;
  width: 96% !important;
  --padding-start: 20px;
  --ion-item-background: white;

  .native-textarea.sc-ion-textarea-md {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .native-textarea.sc-ion-textarea-ios {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.select {
  /*-webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;*/
  border: 2px solid #f1f2f7;
  --border-radius: 25px;
  border-radius: 25px;
  height: 45px;
  margin: 13px;
  --padding-start: 20px;
  //margin-left: 25px;
  //margin-right: 20px;
  ion-label {
    color: grey !important;
    --color: grey !important;
  }
}

ion-button {
  --box-shadow: none;
}

/*.checkbox {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
  --border-radius: 25px;
  border-radius: 25px;
  height: 45px;
  margin: 13px;
  --padding-start: 20px;
  margin-left: 25px;
  margin-right: 20px;

  ion-label {
    color: grey !important;
    --color: grey !important;
  }
}*/

ion-item {
  --inner-padding-end: 0;
  --padding-start: 0;
  --inner-border-width: 0;
}

.logo {
  height: 100px;
  width: auto;
}

.tab-selected {
  color: var(--ion-color-secondary);
}

.item-has-focus {
  --highlight-background: none;
}

.footer-md:before {
  display: none;
}

.label-floating.sc-ion-label-md-h, .label-stacked.sc-ion-label-md-h {
  margin-inline-start: 27px;
  margin-bottom: -27px;
  transform: translate(0px, 28px);
}

.label-floating.sc-ion-label-ios-h, .label-stacked.sc-ion-label-ios-h {
  margin-inline-start: 27px;
  margin-bottom: -27px;
  transform: translate(0px, 28px);
}

.item-has-value .label-floating.sc-ion-label-md-h {
  transform: translate3d(0, 20%, 0) scale(.75)
}

.item-has-value .label-floating.sc-ion-label-ios-h {
  transform: translate3d(0, 20%, 0) scale(.75)
}

ion-item {
  .item-native {
    padding-left: 0 !important;

    .item-inner {
      padding-right: 0 !important;
    }
  }
}


.search-bar {
  input {
    border-radius: 25px !important;
    --border-radius: 25px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .sc-ion-searchbar-md {
    border-radius: 25px !important;
    --border-radius: 25px !important;
  }

  .searchbar-input.sc-ion-searchbar-md {
    border-radius: 25px !important;
    --border-radius: 25px !important;
  }
}

.sc-ion-card-md-h {
  padding-left: 17px;
  padding-right: 17px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  border-radius: 8px;
  border: none;
}

ion-accordion-group {
  padding-left: 17px;
  padding-right: 17px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
  border-radius: 8px;
  border: none;
}

// Date picker
.date-input {
  text-align: right;
  width: 130px;
  border: 0
}

.date-input:focus {
  outline: none;
}

.myDateTime .owl-dt-inline-container,
.myDateTime .owl-dt-popup-container {
  width: 100% !important;
  height: calc(100%) !important;
  box-shadow: none;

  @media screen and (min-width: 333px) and (max-width: 444px) {
    .owl-dt-calendar-multi-year-table {
      margin-top: 100px;
    }
  }
  @media screen and (min-width: 444px) and (max-width: 576px) {
    .owl-dt-calendar-multi-year-table {
      margin-top: 180px;
    }
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    .owl-dt-calendar-multi-year-table {
      margin-top: 226px;
    }
  }

}

.date-picker-modal {
  max-height: 100%;

  @media screen and (min-width: 576px) {
    --max-width: 576px;
  }
  @media screen and (min-width: 768px) {
    @media screen and (min-height: 700px) {
      --max-width: 344px;
      --height: 700px;
    }
    --max-width: 344px;
    --max-height: 700px;
  }


}

.white-background {
  --ion-item-background: white;
  --background: white;
  background: white;
}

// Date picker

.all-screen-modal {
  @media screen and (min-width: 576px) {
    --height: 100%;
    --width: 100%;
  }
}
.full-screen-modal {
  @media screen and (min-width: 576px) {
    --height: 95%;
    --width: 85%;
  }
}

.full-height-modal {
  @media screen and (min-width: 576px) {
      --height: 90%;
  }
}
.half-height-modal {
  @media screen and (min-width: 576px) {
      --height: 70%;
  }
}

//
.autocomplete-search {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 2px;
  -moz-transition: all .51s;
  -o-transition: all .51s;
  -webkit-transition: all .51s;
  transition: all .51s;
}

.autocomplete-list {
  z-index: 10;
  position: absolute;
  width: calc(100% - 28px);
  margin-left: 17px !important;
  padding-bottom: 0;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 12px 40px -3px rgba(0, 0, 0, 0.25);

  ion-item {
    border-bottom: 1px solid grey;
  }
}

.stock-status {
  color: white !important;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  padding: 2px 10px;
  display: inline-block;
}

.order-status-label {
  background: linear-gradient(230deg, #759bff, #843cf6) !important;
}

.payment-status-label{
  background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
  font-weight: 600;
}

.notification-status-label{
  background: linear-gradient(45deg, #439c43, #85bf85) !important;
  font-weight: 600;
}

//stock warning label
.stock-warning-000 {
  --mdc-linear-progress-active-indicator-color: #ff0000;
  --mdc-linear-progress-track-color: red;
}

.stock-warning-020 {
  --mdc-linear-progress-active-indicator-color: orangered;
  --mdc-linear-progress-track-color: aliceblue;
}

.stock-warning-040 {
  --mdc-linear-progress-active-indicator-color: orange;
  --mdc-linear-progress-track-color: aliceblue;
}

.stock-warning-060 {
  --mdc-linear-progress-active-indicator-color: #72c2ff;
  --mdc-linear-progress-track-color: aliceblue;
}

.stock-warning-080 {
  --mdc-linear-progress-active-indicator-color: #86f0ff;
  --mdc-linear-progress-track-color: aliceblue;
}

.stock-warning-100 {
  --mdc-linear-progress-active-indicator-color: #32cd32;
  --mdc-linear-progress-track-color: aliceblue;
}



.confirm-alert {
  --min-width: 350px;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 200px !important;
}


//////////////////
.introjs-helperLayer {
  background: transparent;
}

.introjs-overlay {
  display: none;
}

.introjs-helperLayer:before {
  content: '';
  position: fixed;
  width: inherit;
  height: inherit;
  border-radius: 0.5em;
  border: .2em solid rgba(255, 217, 68, 0.8);
  box-shadow: 0 0 0 1000em rgba(0, 0, 0, .7);
  opacity: 1;
}

.introjs-helperLayer:after {
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

////////////////////////

.delivery-options-popover {
  .popover-content {
    width: auto !important;
  }
}

.margin-auto {
  margin: auto !important;
}

.clearfix {
  overflow: auto;
}

ng-multiselect-dropdown {
  .dropdown-list {
    position: fixed !important;
    width: auto !important;
  }
}

.select-with-image {
  .mdc-list-item__primary-text {
    width: 100%;
    display: flex;
    align-items: center;
  }

  img {
    height: auto;
    width: auto;
    max-width: 35px;
    max-height: 45px;
    margin: auto 5px;
  }
}

.timeWindowWarning {
  position: absolute;
  bottom: 0;
  left: 0;
  color: red;
  z-index: 100;
  background-color: lightgrey;
}

.introjs-donebutton {
  background: #1774ff !important;
  color: white;
  text-shadow: none;
}


#tidio-chat-iframe {
  inset: auto 0px 0px auto !important;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

.tidio-space {
  transform: translateY(-40px) !important;
}

.timepicker-backdrop-overlay {
  z-index: 1001 !important;
}
.timepicker-overlay {
  z-index: 1001 !important;
}

.emphasize-calendar-date {
  .mat-calendar-body-cell-content:after {
    content: "•";
    text-align: center;
    position: absolute;
    bottom: -9px;
    color: orange;
    font-size: 30px;
  }
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

.select-no-height {
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    max-height: unset !important;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    max-height: unset !important;
  }
}
.mat-mdc-card {
  box-shadow: $shadow !important;
  border-radius: 10px !important;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 17px;
  padding-left: 17px;
}
.mat-expansion-panel {
  box-shadow: $shadow !important;
  border-radius: 10px !important;
}

.mapbox-icon {
}

ion-icon {
  pointer-events: none;
}

//label colors
.pickup-label {
  @extend .info-label;
  background: linear-gradient(230deg, #ff743a, #f6ab4d) !important;
  color: white;
}
.delivery-label {
  @extend .info-label;
  background: var(--ion-color-secondary);
  color: var(--ion-color-primary);
}
.paid-label {
  @extend .info-label;
  background: #E5F7E8;
  color: #69BB76;
}
.not-paid-label {
  @extend .info-label;
  background: #FBDBCF;
  color: #DE6B4D;
}
.rejected-delivery-label {
  @extend .info-label;
  background: #FBDBCF;
  color: #DE6B4D;
}
.accepted-delivery-label {
  @extend .info-label;
  background: var(--ion-color-secondary);
  color: var(--ion-color-primary);
}
.notified-customer-label {
  @extend .info-label;
  background: #E5F7E8;
  color: #69BB76;
}

.info-label {
  border-radius: 7px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
}
//label colors

.white-icon {
  stroke: #FFFFFF;
}
.black-icon {
  stroke: #000000;
  mat-icon {
    stroke: #000000;
  }
}
.black-icon:disabled {
  mat-icon {
    stroke: #00000042 !important;
  }
}

.mobile-time-picker {
  background: #f4f5f8;
}

.round-top-panel {
  border-radius: 10px 10px 0 0;
}

@media not all and (min-resolution:.001dpcm) {
  .leaflet-top, .leaflet-left {
    transform: translate3d(0, 0, 0);
    will-change: transform
  }
}

.logistia-marker {
  //z-index: 2 !important;
}

.marker-cluster {
  z-index: 2 !important;
}

//MESSAGES//
.notification-icon {
  color: white;
  float: inline-end;
}

.has-notification {
  width: 12px; /* Adjust as needed */
  height: 12px;
  background-color: red;
  border-radius: 50%; /* Makes it a circle */
  border: 2px solid white; /* Optional: Adds a white border to the bubble for better visibility */
  z-index: 2;
  float: inline-end;
  position: absolute;
}
//MESSAGES//

@media (min-width: 992px) {
  .mat-form-field-appearance-outline .mat-form-field-label {
    font-size: 13px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    font-size: 13px;
  }
}

.account-name {
  color: white;
  margin-top: 17px;

  .material-icons {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .name {
    vertical-align: super;
  }
}

.app-full-bleed-dialog .mat-dialog-container {
  padding: 0;
}

.ion-text-bold {
  font-weight: 700;
}

.lg-time-picker {
  direction: ltr;
  border-radius: $border-radius !important;
  .timepicker__header {
    border-radius: $border-radius $border-radius 0 0;
  }
  .timepicker__actions {
    border-radius: 0 0 $border-radius $border-radius;
  }
}
.mat-dialog-container {
  @media screen and (min-width: 768px) {
    border-radius: $border-radius !important;
  }

}

.safe-area-bottom {
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.white-mat-form {
  .mat-mdc-text-field-wrapper {
    background: white;
  }
}

.logistia-table {
  th {
    background-color: aliceblue !important;
    color: royalblue !important;
    font-weight: 700;
  }
}
button.alert-button.primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
button.alert-button.danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.hidden {
  display: none;
}

.button-spinner {
  width: 20px;
  height: 14px;
}
